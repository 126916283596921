import React, { Component } from 'react';
import styled from 'styled-components';

import Button from './Button';

import MainCaption from './../Content/MainCaption.json';
import cover from './../img/cover.jpg';
import logo from './../img/cda-logo.png';

import { isMobile, isTabletOrBelow } from '../mediaQuery';

export default class Main extends Component {
  render() {
    return (
      <Wrapper id="home">
        <Background />
        <Container>
          <Image src={logo} />
          <Caption>{MainCaption.Caption}</Caption>
          <ButtonContainer>
            <StyledButton
              color
              link="https://forms.gle/GNi1Cba2QpdcvV4JA"
              title={MainCaption.Registration}
            />
            <StyledButton
              color
              link="https://drivetest.ca/book-a-road-test/overview/"
              title={MainCaption.Booking}
            />
          </ButtonContainer>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${cover});
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(30%) grayscale(80%);

  ${isMobile} {
    background-size: unset;
  }
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  left: 0;
  width: 100%;
  height: 100%;
  top: 100px;
`;

const Caption = styled.div`
  display: block;
  width: 90%;
  text-align: center;
  color: white;
  font-size: 24px;
  text-align: center;
  margin-top: 60px;

  ${isTabletOrBelow} {
    width: 80%;
    font-size: 18px;
  }

  ${isMobile} {
    font-size: 14px;
  }
`;

const Image = styled.img`
  display: block;
  width: 500px;
  background: transparent;
  margin: 24px 0 36px;

  ${isMobile} {
    width: 300px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20%;
  display: flex;
  flex-direction: row;

  ${isMobile} {
    bottom: 30%;
  }
`;

const StyledButton = styled(Button)`
  position: relative;
  margin: 0 16px;
  bottom: 0;

  ${isMobile} {
    font-size: 16px;
    padding: 8px 16px;
  }
`;
