import React, { Component } from 'react';
import styled from 'styled-components';

export default class Button extends Component {
  render() {
    return (
      <Link className={this.props.className} href={this.props.link} target="_blank">{this.props.title}</Link>
    );
  }
}

const Link = styled.a`
  position: absolute;
  bottom: 160px;
  color: white;
  text-align: center;
  padding: 1em 3em;
  font-size: 2em;
  border-radius: 15px;
  border: 2px solid white;
  transition: 0.2s linear all;
  text-decoration: none;
  
  &:hover {
    background: red;
  }
`;
