import React, { Component } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { HeadingBlack } from './Partials/Title';
import CardList from './Partials/Card_list';
import CaroselCard from './Partials/Carosel_Card';
import Colors from '../variables/Colors';
import testimonials from '../Content/Testimonials.json';
import teamInfo from '../Content/TeamInfo.json';

import { isMobile, isTabletOrBelow, MediaQueries } from '../mediaQuery';
import Card from './Partials/Card';

const mq = new MediaQueries();


export default class Testimonials extends Component {
  render() {
    const { isEnglish } = this.props;
    return (
      <Wrapper id="testimonials">
        {isEnglish ? (
          <>
            <StyledHeadingBlack name="Testimonials" />
            <CaroselContainer>
              <Carousel
                infiniteLoop
                centerMode
                centerSlidePercentage={mq.isMobile ? 60 : 30}
                dynamicHeight
                autoPlay
                showThumbs={false}
                showStatus={false}
              >
                {testimonials.body.map((testimonial, i) => (
                  <CaroselCard
                // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    name={testimonial.name}
                    text={testimonial.comment}
                  />
            ))}
              </Carousel>
            </CaroselContainer>
          </>
        ) : (
          null
          // <>
          //   <StyledHeadingBlack name="教练队伍" />
          //   <Row>
          //     <CardList
          //       title={teamInfo.Title_1}
          //       content={teamInfo.List_1}
          //     />
          //     <Card
          //       content_1={teamInfo.Paragraph_1}
          //     />
          //     <CardList
          //       title={teamInfo.Title_2}
          //       content={teamInfo.List_2}
          //     />
          //   </Row>
          // </>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  background-color: ${Colors.background};
  /* min-height: calc(100% - 80px); */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  scroll-margin: 80px;
`;

const CaroselContainer = styled.div`
  width: 80%;
  margin: 24px 0 40px 0;

  ${isTabletOrBelow} {
    width: 100%;
  }
`;

const StyledHeadingBlack = styled(HeadingBlack)`
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  margin-top: 48px;
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  ${isMobile} {
    width: 80%;
  }
`;
