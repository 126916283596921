import React, { Component } from 'react';
import styled from 'styled-components';

import colors from './../variables/Colors';

import { HeadingWhite } from './Partials/Title';
import Card from './Partials/Card';
import CardList from './Partials/Card_list';
import Board from './Partials/Board';

import ServiceList from './../Content/Services.json';
import { isMobile, isTabletOrBelow } from '../mediaQuery';

export default class Services extends Component {
  render() {
    const { isEnglish } = this.props;
    return (
      <Wrapper id="service">
        <StyledHeadingWhite name={isEnglish ? 'Services' : '服务种类'} />
        <Container>
          {isEnglish ? (
            <>
              <Card
                content_1={ServiceList.Paragraph_1}
                content_2={ServiceList.Paragraph_2}
              />
              <StyledCardList content={ServiceList.List_2} />
            </>
            ) : (
              <Card content_1={ServiceList.Paragraph_1_CN} />
            )}
        </Container>
        <BoardContainer>
          {isEnglish ? (
            <>
              <Board
                title="Full Course ($820)"
                content={ServiceList.PackageFullCourse}
              />
              <Board
                title="Road Tests"
                content={ServiceList.PackageRoadTest}
              />
              <Board
                title="In-Vehicle Training"
                content={ServiceList.IndividualLessons}
              />
            </>
            ) : (
              <>
                <Board
                  title="安省交通部全科课程"
                  content={ServiceList.PackageFullCourse_CN}
                />
                <Board
                  title="路考"
                  content={ServiceList.PackageRoadTest_CN}
                />
                <Board
                  title="散钟练车"
                  content={ServiceList.IndividualLessons_CN}
                />
              </>
                       )}
        </BoardContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  left: 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  scroll-margin: 80px;

  ${isMobile} {
    box-sizing: border-box;
    padding: 0 24px;
  }
`;

const BoardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  margin: 24px 0 64px 0;

  ${isTabletOrBelow} {
    margin: 32px 0 64px 0;
    width: 100%;
  }

  ${isMobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const Container = styled.div`
    color: ${colors.lightGrey_1};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledHeadingWhite = styled(HeadingWhite)`
  position: relative;
  display: block;
  transform: none;
  top: auto;
  left: auto;
  padding-top: 48px;
  margin-bottom: 24px;
`;

const StyledCardList = styled(CardList)`
  width: 100%;
  margin-left: 56px;

  p {
    margin: 0;
  }
`;
