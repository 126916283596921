/* eslint-disable no-new */
import { Wrapper } from '@googlemaps/react-wrapper';
import React, { useRef, useEffect } from 'react';

function MyMapComponent({
  center,
  zoom,
}) {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });

    new window.google.maps.Marker({
      position: center,
      map,
    });
  });

  return <div ref={ref} id="map" />;
}

export default class Map extends React.Component {
  render() {
    const center = { lat: 43.480543, lng: -80.522755 };
    const zoom = 15;
    return (
      <Wrapper
        apiKey="AIzaSyDi38sMlWU7tqFPCKlBzjABdd5Z9Lc65Uw"
      >
        <MyMapComponent center={center} zoom={zoom} />
      </Wrapper>
    );
  }
}
