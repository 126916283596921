import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile, isTabletOrBelow, MediaQueries } from '../mediaQuery';

import Colors from '../variables/Colors';
import Map from './Partials/Map';
import { HeadingWhite } from './Partials/Title';

const mq = new MediaQueries();


export default class Contact extends Component {
  render() {
    const { isEnglish } = this.props;
    return (
      <Wrapper id="contact">
        <StyledHeadingWhite name={isEnglish ? 'Contact Us' : '联系方式'} />
        {mq.isMobile ? (
          <Column>
            <Column>
              <Title>
                {isEnglish ? 'Address' : '地址:'}
              </Title>
              <Text>
                285 Weber St. North Unit #1A <br />
                Waterloo, Ontario <br />
                N2J 3H8
              </Text>
            </Column>
            <Column>
              {isEnglish ? (
                <Title>
                  Have a specific instructor in mind?
                </Title>
              ) : <Title>李教练</Title>}
              { isEnglish ? (
                <Text>
                  Wei Li: (226) 600 0836 <br />
                  WeChat ID: CanadianDA
                </Text>
              ) : (
                <Text>
                  电话号码: (226) 600 0836 <br />
                  微信号: CanadianDA
                </Text>
                  )}
            </Column>
            <Column>
              <Title>
                {isEnglish ? 'Email' : '邮箱:'}
              </Title>
              <Text>
                Canadiandrivingacademy@gmail.com
              </Text>
            </Column>
            <Column>
              <Title>
                {isEnglish ? 'Phone Number' : '电话号码:'}
              </Title>
              <Text>
                (519) 880-0030
              </Text>
            </Column>
          </Column>) : (
            <>
              <Row>
                <Column>
                  <Title>
                    {isEnglish ? 'Address' : '地址:'}
                  </Title>
                  <Text>
                    285 Weber St. North Unit #1A <br />
                    Waterloo, Ontario <br />
                    N2J 3H8
                  </Text>
                </Column>
                <Column>
                  <Title>
                    {isEnglish ? 'Have a specific instructor in mind?' : '李教练'}
                  </Title>
                  { isEnglish ? (
                    <Text>
                      Wei Li: (226) 600 0836 <br />
                      WeChat ID: CanadianDA
                    </Text>
                    ) : (
                      <Text>
                        电话号码: (226) 600 0836 <br />
                        微信号: CanadianDA
                      </Text>
                    )}
                </Column>
              </Row>
              <Row>
                <Column>
                  <Title>
                    {isEnglish ? 'Email' : '邮箱:'}
                  </Title>
                  <Text>
                    Canadiandrivingacademy@gmail.com
                  </Text>
                </Column>
                <Column>
                  <Title>
                    {isEnglish ? 'Phone Number' : '电话号码:'}
                  </Title>
                  <Text>
                    (519) 880-0030
                  </Text>
                </Column>
              </Row>
            </>
        )}
        <Row>
          <MapContainer>
            <Map />
          </MapContainer>
        </Row>
      </Wrapper>
    );
  }
}

const MapContainer = styled.div`
  box-sizing: border-box;
  width: 600px;
  height: 400px;
  margin: 32px 0 48px 0;

  ${isTabletOrBelow} {
    height: 300px;
    width: 450px;
  }

  ${isMobile} {
    width: 90%;
    height: 300px;
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: ${Colors.darkBlue_2};
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  scroll-margin: 80px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: fit-content;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
  width: 250px;

  ${isMobile} {
    margin: 24px 0;
  }
`;

const StyledHeadingWhite = styled(HeadingWhite)`
  position: relative;
  top: auto;
  left: auto;
  height: auto;
  transform: none;
  margin: 48px 0 0 0;
`;

const Title = styled.h2`
  font-size: 20px;
  color: ${Colors.red};
`;

const Text = styled.p`
font-size: 16px;
  color: white;
`;
