import React, { Component } from "react";
import styled from "styled-components";
import { isMobile } from "../../mediaQuery";

import colors from "./../../variables/Colors";

export default class Board extends Component {
  render() {
    return (
      <Wrapper>
        <BoardFront>
          <Image />
          <Text>
            <Heading>{this.props.title}</Heading>
            <Content>
              {this.props.content.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={i}>{item}</ListItem>
              ))}
            </Content>
          </Text>
        </BoardFront>
        <BoardBack>
          <Button target="_blank" href="https://forms.gle/GNi1Cba2QpdcvV4JA">
            Register Now
          </Button>
        </BoardBack>
      </Wrapper>
    );
  }
}

const BoardFront = styled.div`
  display: block;
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 1px 2px 8px ${colors.blueGrey_1};
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  overflow: hidden;
`;

const BoardBack = BoardFront.extend`
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730);
  transform: rotateY(180deg);
`;

const Wrapper = styled.div`
  position: relative;
  width: 250px;
  height: 450px;
  perspective: 700px;

  &:hover ${BoardFront} {
    transform: rotateY(-180deg);
  }

  &:hover ${BoardBack} {
    transform: rotateY(0);
  }

  ${isMobile} {
    margin-bottom: 24px;
  }
`;

const Text = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  top: 27%;
`;

const Heading = styled.h1`
  font-size: 2em;
  font-weight: 400;
  color: ${colors.red};
  text-align: center;
  margin-bottom: 1.5em;
`;

const Content = styled.div`
  font-size: 1.5em;
  color: ${colors.black};
  z-index: 10;
  max-width: 15em;
  margin: 0 2em;
  list-style: none;
  text-align: center;
`;

const ListItem = styled.li`
  margin-bottom: 1em;
  padding-bottom: 1em;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.lightGrey_2};
  }
`;

const Image = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: orange;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  z-index: 0;
`;

const Button = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  text-decoration: none;
  color: orange;
  background: ${colors.white};
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 15px;
  transition: 0.2s all linear;

  &:hover {
    box-shadow: 0 1em 1em rgba(0, 0, 0, 0.2);
  }
`;
