const colors = {
  white: '#ffffff',
  black: '#000000',
  red: '#e71d26',
  lightGrey_1: '#D3D3D3',
  lightGrey_2: '#E8E8E8',
  background: '#EDECEC',
  navBarBackground: '#182129',
  greyDark_1: '#999',
  greyDark_2: '#777',
  greyDark_3: '#333',
  blueGrey_1: '#607D8B',
  blueGrey_2: '#546e7a',
  blueGrey_3: '#455A64',
  darkBlue_1: '#1B2735',
  darkBlue_2: '#090A0F',
};

export default colors;
