import React, { Component } from 'react';
import styled from 'styled-components';
import { isTabletOrBelow } from '../../mediaQuery';

import Colors from './../../variables/Colors';
import { HeadingBlack } from './Title';

export default class CaroselCard extends Component {
  render() {
    return (
      <Wrapper>
        <StyledHeadingBlack name={this.props.name} />
        <Text>{this.props.text}</Text>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 8px;
  width: 250px;
  height: 350px;
  margin: 0 8px;
  box-shadow: 1px 1px 3px ${Colors.greyDark_1};

  ${isTabletOrBelow} {
    width: 200px;
    height: 300px;
  }
`;

const StyledHeadingBlack = styled(HeadingBlack)`
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  font-size: 16px;
  margin-top: 32px;
  margin-bottom: 8px;

  &:after {
    bottom: -5px;
  }
`;

const Text = styled.p`
  font-size: 12px;
  padding: 4px 24px;
  line-height: 16px;
  max-height: 250px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  color: ${Colors.greyDark_2}

  ${isTabletOrBelow} {
    font-size: 8px;
  }
`;
