import React, { Component } from 'react';
import styled from 'styled-components';

import Navbar from './components/Navbar';
import Main from './components/Main';
import About from './components/About';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEng: true,
    };
  }

  toggleState = () => {
    this.setState({
      isEng: !this.state.isEng,
    });
  };

  render() {
    return (
      <Wrapper>
        <Navbar
          handleLanguageChange={this.toggleState}
          isEnglish={this.state.isEng}
        />
        <Main isEnglish={this.state.isEng} />
        <ScrollingContainer>
          <About isEnglish={this.state.isEng} />
          <Services isEnglish={this.state.isEng} />
          <Testimonials isEnglish={this.state.isEng} />
          <Contact isEnglish={this.state.isEng} />
        </ScrollingContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  /* min-height: 100vh; */
  width: 100%;
`;

const ScrollingContainer = styled.div`
  position: absolute;
  top: 100%;
  height: 100%;
  width: 100%;
`;

export default App;
