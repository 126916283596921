import React, { Component } from 'react';
import styled from 'styled-components';
import { MediaQueries } from '../mediaQuery';

const mq = new MediaQueries();

export default class Sectionbtn extends Component {
  render() {
    return (
      <Button
        onClick={() => {
          if (this.props.elementId) {
            document.getElementById(this.props.elementId).scrollIntoView({
              behavior: 'smooth',
            });
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }}
      >
        {mq.isMobile ? <Image src={this.props.img} alt={this.props.label} /> : this.props.label}
      </Button>
    );
  }
}

const Button = styled.div`
  font-size: 18px;
  color: white;
  cursor: default;
`;

const Image = styled.img`
  width: 25px;
  height: 25px;
`;

