import React, { Component } from 'react';
import styled from 'styled-components';

import colors from './../variables/Colors';

import { HeadingBlack } from './Partials/Title';
import Card from './Partials/Card';
import CardList from './Partials/Card_list';

import jetta from './../img/volkswagen.png';

import AboutUs from './../Content/AboutUs.json';

import { isMobile } from '../mediaQuery';

export default class About extends Component {
  render() {
    const { isEnglish } = this.props;
    return (
      <Wrapper id="about">
        <StyledHeadingBlack name={isEnglish ? 'About Us' : '关于我们'} />
        <Container>
          <Content>
            <Card
              title={isEnglish ? 'Canadian Driving Academy' : '欢迎来到 加拿大驾驶学院'}
              content_1={isEnglish ? AboutUs.AboutUs_1 : AboutUs.AboutUs_1_CN}
              content_2={isEnglish ? AboutUs.AboutUs_2 : ''}
            />
            {isEnglish ? <CardList
              title="Why Choose Us?"
              content={AboutUs.AboutUs_3}
            /> : <Card
              title="为什么选择 加拿大驾驶学院"
              content_1={AboutUs.AboutUs_2_CN}
            />}
          </Content>
          <Image src={jetta} />
        </Container>
      </Wrapper>
    );
  }
}

const StyledHeadingBlack = styled(HeadingBlack)`
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  margin-top: 36px;
`;

const Wrapper = styled.div`
  background-color: ${colors.background};
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 36px;
  scroll-margin: 80px;
  min-height: calc(100% - 80px);
`;

const Content = styled.div`
  width: 100%;
  margin: 36px 0 0 36px;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${isMobile} {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 400px;
  
  ${isMobile} {
    width: 300px;
  }
`;
