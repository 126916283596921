/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import styled from 'styled-components';

import colors from './../../variables/Colors';

export default class Card extends Component {
  render() {
    const list = this.props.content;
    return (
      <Wrapper className={this.props.className}>
        <Heading>{this.props.title}</Heading>
        <Content className={this.props.className}>
          {list.map((item, i) => <List key={i}>{item}</List>)}
        </Content>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 8px 4px;
  border-radius: 4px;
  opacity: 70%;
`;

const Heading = styled.h1`
  display: block;
  font-size: 18px;
  font-weight: 400;
  color: ${colors.red};
  margin: 0;
`;

const Content = styled.p`
  font-size: 1.5em;
  display: block;
  max-width: 400px;
  margin: 32px 0;
  line-height: 20px;
`;

const List = styled.li`
  list-style-position: outside;
  margin-left: 40px;
  text-indent: -36px;
`;
