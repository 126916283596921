import React, { Component } from 'react';
import styled from 'styled-components';

import colors from './../../variables/Colors';

export default class Card extends Component {
  render() {
    return (
      <Wrapper>
        <Heading>{this.props.title}</Heading>
        <Content removeMargin={!!this.props.title}>{this.props.content_1}</Content>
        <Content>{this.props.content_2}</Content>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 0 4px;
`;

const Heading = styled.h1`
  display: block;
  margin: 0;
  font-size: 2em;
  font-weight: 400;
  color: ${colors.red};
`;

const Content = styled.p`
  font-size: 1.5em;
  display: block;
  max-width: 32em;
  margin-top: ${({ removeMargin }) => (removeMargin ? 0 : '2em')};
  line-height: 20px;
`;
