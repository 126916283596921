import React, { Component } from 'react';
import styled from 'styled-components';
import Sectionbtn from './Sectionbtn';

import colors from './../variables/Colors';

import homeIcon from '../img/home.svg';
import aboutUsIcon from '../img/info.svg';
import serviceIcon from '../img/service.svg';
import ratingIcon from '../img/rating.svg';
import contactIcon from '../img/contact.svg';
import languageIcon from '../img/language.svg';
import { MediaQueries } from '../mediaQuery';

const mq = new MediaQueries();

export default class Navbar extends Component {
  render() {
    const { isEnglish, handleLanguageChange } = this.props;
    return (
      <PositionWrapper>
        {/* <TopAnnouncementBanner>
          All prices start May 1st, 2023
        </TopAnnouncementBanner> */}
        <Main>
          <Sectionbtn
            label={isEnglish ? 'Home' : '主页'}
            img={homeIcon}
          />
          <Sectionbtn
            label={isEnglish ? 'About Us' : '关于我们'}
            elementId="about"
            img={aboutUsIcon}
          />
          <Sectionbtn
            label={isEnglish ? 'Services' : '服务种类'}
            elementId="service"
            img={serviceIcon}
          />
          {isEnglish ? <Sectionbtn
            label="Testimonials"
            elementId="testimonials"
            img={ratingIcon}
          /> : null}
          <Sectionbtn
            label={isEnglish ? 'Contact Us' : '联系方式'}
            elementId="contact"
            img={contactIcon}
          />
          {mq.isMobile ? (
            <LanguageImg
              onClick={handleLanguageChange}
              src={languageIcon}
            />
        ) : (
          <Language
            onClick={handleLanguageChange}
          >{isEnglish ? 'Chinese' : 'English'}
          </Language>)}
        </Main>
      </PositionWrapper>
    );
  }
}

const TopAnnouncementBanner = styled.div`
  height: 35px;
  background-color: ${colors.red}; 
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.lightGrey_1};
`;

const PositionWrapper = styled.div`
  position: fixed;
  width: 100%;  
  z-index: 100000;
`;

const Main = styled.div`
  height: 80px;
  background-color: ${colors.navBarBackground};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 3px solid #333;
`;

const Language = styled.div`
  font-size: 18px;
  color: white;
  cursor: default;
`;

const LanguageImg = styled.img`
  width: 25px;
  height: 25px;
`;
