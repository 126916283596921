import React, { Component } from 'react';
import styled from 'styled-components';

import colors from './../../variables/Colors';

export class HeadingBlack extends Component {
  render() {
    return (
      <TitleBlack className={this.props.className}>
        {this.props.name}
      </TitleBlack>
    );
  }
}


export class HeadingWhite extends Component {
  render() {
    return (
      <TitleWhite className={this.props.className}>
        {this.props.name}
      </TitleWhite>
    );
  }
}

const TitleBlack = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 3em;

  &::after {
    content: '';
    position: absolute;
    font-size: 18px;
    bottom: -15px;
    left: 50%;
    width: 60px;
    height: 1px;
    margin-left: -30px;
    background-color: ${colors.red};
  }
`;

const TitleWhite = TitleBlack.extend`
  color: white;
`;
